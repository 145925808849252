export const invoicing_options = [
    'R$ 0 - R$ 7.000',
    'R$ 7.000 - R$ 15.000',
    'R$ 15.000 - R$ 30.000',
    'R$ 30.000 - R$ 50.000',
    'R$ 50.000 - R$ 100.000',
    'R$ 100.000 - R$ 250.000',
    'R$ 250.000+',
]
export const client_base_options = [
    '0 - 500',
    '500 - 1.000',
    '1.000 - 3.000',
    '3.000 - 5.000',
    '5.000 - 10.000',
    '10.000 - 25.000',
    '25.000 - 50.000',
]
